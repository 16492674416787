$(function() {
  $('a[href^=javascript][href*="eListing"]').on('click', function(e) {
    const has_signed_up_cookie = document.cookie.split('; ').find((row) => row.startsWith('signed_up='));
    e.preventDefault();
    if (has_signed_up_cookie) {
      const csrf_token = document.querySelector('meta[name=csrf-token]').content;
      $.ajax('/elisting_ping', {
        type: 'POST',
        headers: { 'X-CSRF-Token': csrf_token },
        credentials: 'same-origin',
        complete: function(data) {
          window.location = '/listings';
        }
      });
    } else if ($('.modal#elisting-modal').length) {
      $('#elisting-modal').modal('show');
    } else {
      $.ajax('/elisting', {
        type: 'GET',
        dataType: 'html',
        success: function(data, textStatus, jqXHR) {
          let listing_link = $('a#go-to-listings', data);
          if (listing_link.length) {
            window.location = $(listing_link).attr('href');
          } else {
            $('body').append(data);
            $('#elisting-modal').modal('show');
            $('#opportunity-form').on('submit', function(e) {
              if ($('#g-recaptcha-response').val() == '') {
                alert("Please verify that you are not a robot.");
                e.preventDefault();
                return false;
              }
            });
          }
        }
      });
    }
  });
});
